//link will replace href

const Footer = () => {
    return (
      <footer id="sticky-footer" class="py-4 text-white-50" style={{ backgroundColor: 'hsl(207, 3%, 40%)' }}>
        
          <p>Copyright &copy; Elijah Logan</p>
  
        
      </footer>
    )
  }
  
  export default Footer;