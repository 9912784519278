import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import NavBar from './containers/NavBar';
import Landing from './containers/Landing';
import Footer from './containers/Footer';

import 'bootstrap/dist/css/bootstrap.min.css';






class App extends Component {
  constructor(props) {
    super(props)
    this.state = { recipes: [] }
  }
  async componentWillMount() {

  }


  // async searchEdam(term) {
  //   let recipes = await Edam.search(term)
  //   console.log(recipes)
  //   let data = recipes.data.recipes
  //   this.setState({ recipes: data })
  //   console.log(this.state.recipes)
  // }

  render() {
    return (

      <Router>
          <NavBar />
          <Route exact path="/" component={Landing} />
   
          <section >
            <Switch>
   
            </Switch>
          </section>
          <Footer/>
      </Router>


    );
  }
}

export default App;
