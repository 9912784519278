import React, { useState } from 'react'
import { Link } from 'react-router-dom';



import { Navbar, Nav, NavDropdown, FormControl, Form, Button } from 'react-bootstrap';



//link will replace href

const NavBar = () => {
  const [color, setColor] = useState(true)



  const toggleHover = () => setColor(!color)

  let linkStyle;
  if (color) {
    linkStyle = {
      transition: 'background-color 0.5s ease',
      backgroundColor: 'white'
    }
  } else {
    linkStyle = {
      backgroundColor: '#858585',
      transition: 'background-color 0.5s ease'
    }
  }
  return (
    <Navbar style={linkStyle} sticky="top" 
      onMouseEnter={toggleHover} onMouseLeave={toggleHover} onScroll={toggleHover} onTap={toggleHover} 
      expand="lg">
      <Navbar.Brand href="#home"><Link to='/'> Elijah Logan </Link> </Navbar.Brand>

    </Navbar>
  )
}

export default NavBar